"use strict";

// NOTE TO SELF
// Found better example code to reference for improved a11y on drop menus...
// https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
//
// More at
// https://www.w3.org/TR/wai-aria-practices/
// https://www.w3.org/TR/wai-aria-practices/examples/

// let originalMarkup = document.querySelector('header').cloneNode(true);
let navMain        = document.querySelector('header .nav_main');
let groupedContent = document.querySelector('header .groupedContent');
let siteTitle      = document.querySelector('.site_title');

// helper functions
	const handleFocusInHeaderMobile = (e) => {
		console.log(e.target.id);
		if (['mainNavToggle','headerLogoAnchor'].includes(e.target.id)) { // don't do anything, as there's gonna be a click...
			return;
		}
		document.querySelector('#mainNavToggle').setAttribute("aria-expanded", 'true');
		document.querySelector('html').classList.add("nav-active");
	};

	const handleFocusOutHeaderMobile = (e) => {
		document.querySelector('#mainNavToggle').setAttribute("aria-expanded", 'false');
		document.querySelector('html').classList.remove("nav-active");
	};
	const handleMainNavToggle = (e) => {
		console.log('clicked #mainNavToggle');
		let newNavStatus;
		let navIsOpen = document.querySelector('html').classList.contains("nav-active"); // returns true|false
		newNavStatus  = !navIsOpen; // inverts the value

		document.querySelector('#mainNavToggle').setAttribute("aria-expanded", newNavStatus); // applies new value
		document.querySelector('html').classList.toggle("nav-active");
	};

	const handleSearchButtonClick = (e) => {
		e.preventDefault();

		let toggleButtonSearch = document.querySelector('header .searchBarTrigger a');
		let toggleButtonMenu   = document.querySelector('header .menuTrigger a');
		let htmlElement        = document.querySelector('html');

		if (htmlElement.classList.contains('js-menu-open')) {
			htmlElement.classList.remove('js-menu-open');

			setTimeout(() => {
				htmlElement.classList.toggle('js-search-open');

				if (htmlElement.classList.contains('js-search-open')) {
					document.querySelector('#searchTerm').focus({
						preventScroll: true
					});
				}
			}, 300);
		} else {
			htmlElement.classList.toggle('js-search-open');

			if (htmlElement.classList.contains('js-search-open')) {
				document.querySelector('#searchTerm').focus({
					preventScroll: true
				});
			}
		}
	};

	const handleMenuButtonClick = (e) => {
		e.preventDefault();

		let toggleButtonSearch = document.querySelector('header .searchBarTrigger a');
		let toggleButtonMenu   = document.querySelector('header .menuTrigger a');
		let htmlElement        = document.querySelector('html');

		if (htmlElement.classList.contains('js-search-open')) {
			htmlElement.classList.remove('js-search-open');

			setTimeout(() => {
				htmlElement.classList.toggle('js-menu-open');
			}, 300);
		} else {
			htmlElement.classList.toggle('js-menu-open');
		}
	}

// main stuff
function doMobileNavigation() {
	console.log('doMobileNavigation');
	document.querySelector('html').classList.add('mobile-nav');

	// move the .nav_main node into the .groupedContent div
		groupedContent.insertAdjacentElement("afterbegin", navMain);

	// create the navigation toggle button
		let templateToggleNav = `
			<button id='mainNavToggle' aria-label="Main Menu"><span class="burger"><span class="line"></span></span><span class="uc_hide-visually">Toggle Main Navigation</span></button>
		`;
		document.querySelector('header .site_title').insertAdjacentHTML('afterend', templateToggleNav);
		let navToggle = document.querySelector('#mainNavToggle');

	// Accessilbity enhancements for the dynamic toggling control
		let theMenu = document.querySelector('#site_navigation');
		theMenu.setAttribute("aria-labelledby", "mainNavToggle");

	// handle clicks on the menu toggle button
		navToggle.addEventListener("click", handleMainNavToggle);

	// handle keyboard focussing inside the nav
	// NOTE: this could be done in CSS with :focus-within, but then you lose ARIA status updates
		document.querySelector('header').addEventListener('focusin', handleFocusInHeaderMobile);
		document.querySelector('header').addEventListener('focusout', handleFocusOutHeaderMobile);
}

function undoMobileNavigation() {
	console.log('undoMobileNavigation');

	// remove event listeners
	document.querySelector('#mainNavToggle')?.removeEventListener("click", handleMainNavToggle);
	document.querySelector('header').removeEventListener('focusin', handleFocusInHeaderMobile);
	document.querySelector('header').removeEventListener('focusout', handleFocusOutHeaderMobile);

	// remove classes and revert mark-up changes
	document.querySelector('html').classList.remove('mobile-nav');
	if( document.querySelector('#mainNavToggle') ) {
		document.querySelector('html').classList.remove("nav-active");
		document.querySelector('#mainNavToggle')?.remove();
		document.querySelector('#site_navigation').removeAttribute('aria-labelledby');

		// move the navMain back to where it was
			siteTitle.insertAdjacentElement("afterend", navMain);
	}
}

function doDesktopNavigation() {
	console.log('doDesktopNavigation');

	let toggleButtonSearch = document.querySelector('header .searchBarTrigger a');
	let toggleButtonMenu   = document.querySelector('header .menuTrigger a');

	toggleButtonSearch.addEventListener('click', handleSearchButtonClick);
	toggleButtonMenu.addEventListener('click', handleMenuButtonClick);
}

function undoDesktopNavigation() {
	console.log('undoDesktopNavigation');
	let toggleButtonSearch = document.querySelector('header .searchBarTrigger a');
	let toggleButtonMenu   = document.querySelector('header .menuTrigger a');

	//remove event listeners
		toggleButtonSearch.addEventListener('click', handleSearchButtonClick);
		toggleButtonMenu.addEventListener('click', handleMenuButtonClick);

	// "close" everything
		document.querySelector('html').classList.remove('js-menu-open','js-search-open');
}

let screenWidth = window.matchMedia('(max-width: 1199px)');

// initial load
if( screenWidth.matches ) {
	undoDesktopNavigation();
	doMobileNavigation();
} else {
	undoMobileNavigation();
	doDesktopNavigation();
}

// watch for changes in window size
screenWidth.addEventListener("change", (e) => {
	if( e.matches ) {
		undoDesktopNavigation();
		doMobileNavigation();
	} else {
		undoMobileNavigation();
		doDesktopNavigation();
	}
});

// watch for clicks to dismiss the nav elements
document.querySelector('main,footer,.row.newsletter').addEventListener('click', () => {
	document.querySelector('html').classList.remove('js-menu-open','js-search-open');
});
